<template>
  <div>
    <div class="payment-efecty-baloto">
      <p class="mb-4">
        El código para pago se mostrará al finalizar la compra. Ten en cuenta
        que el plazo máximo para realizar el pago en cualquier punto Efecty es
        de <b>24 horas</b>, pasado este plazo tu pedido será cancelado
        automáticamente.* Recuerda que el pago mínimo es de $20.000 pesos.
      </p>
      <div class="d-flex justify-content-center mb-4 col-12 col-lg-8 mx-auto">
        <button
          class="btn-primary btn-finalize-payment w-100"
          type=""
          @click="validatePayment()"
        >
          Continuar con Efecty
        </button>
      </div>
      <div class="text-center">
        <span v-if="errorPaymentBillBalotoEfecty" class="msgError">
          {{ errorPaymentBillBalotoEfecty }}
        </span>
      </div>
      <!-- <div class="alert alert-warning alert-warning-edit">
        Tu pedido será cancelado si no realizas el pago antes del
        <b>miércoles 7 junio2023</b>
      </div> -->
      <p style="font-size: 12px; display: block">
        No demores en pagar, solo podemos reservarte los productos cuando el
        pago se acredite
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'
import { sendCriteoEventCriteo } from '@/criteo/events'
export default {
  data() {
    return {
      typePago: 'efecty',
    }
  },
  computed: {
    ...mapState('payment', [
      'errorPaymentBillBalotoEfecty',
      'dataSummaryPayment',
      'dataCartAnonymous',
      'msgModalExcepcion406',
      'esFE',
    ]),
    getDataAnalytics() {
      let arrayTemp = []
      this.dataCartAnonymous.productos.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    getDataProductsCRTO() {
      let arrayTemp = []
      this.dataCartAnonymous.productos.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  methods: {
    ...mapActions('payment', ['paymentEfectyBaloto']),
    async validatePayment() {
      if (this.typePago) {
        this.$store.state.loaderDerek = true
        sendFBQEvent(this, 'InitiateCheckout', {})
        await this.paymentEfectyBaloto({
          typePago: this.typePago,
          token: this.$route.params.tokenCart,
          esFE: this.esFE,
        })
          .then(() => {
            if (this.msgModalExcepcion406) {
              this.$bvModal.show('modal-error-406')
            } else if (!this.errorPaymentBillBalotoEfecty) {
              GA4AddPaymentAnonymous('Efecty')
              GA4PurchaseAnonymous()
              sendFBQEvent(this, 'Purchase', {
                value: this.dataCartAnonymous.carrito.carrito_total,
                currency: 'COP',
                contents: this.dataSummaryPayment.productos_purchase,
                content_ids: this.dataSummaryPayment.productos_purchase.map(
                  e => e.id,
                ),
                event_id: this.dataSummaryPayment.pedido.pedido
                  .pedido_reference_sale,
                content_type: 'product',
              })
              sendCriteoEventCriteo([
                {
                  event: 'trackTransaction',
                  id: this.dataSummaryPayment.pedido.carrito.reference_sale,
                  item: this.getDataProductsCRTO,
                },
              ])
              this.$emit('nextStep')
              this.$emit('nextStep', 1)
            }
            this.$store.state.loaderDerek = false
          })
          .catch(() => {
            this.$store.state.loaderDerek = false
          })
      }
    },
  },
}
</script>
